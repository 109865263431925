<template>
  <SideBar />
  <router-view class="noscroll"></router-view>
</template>

<script>
import SideBar from "@/components/SideBar.vue";

export default {
  name: "App",
  components: {
    SideBar,
  },
};
</script>
<style>
:root {
  --success-color: #03a01c;
  --success-soft: #cefdde;
  --error-color: #f32051;
  --text-highlight: #3348ff;
  --text-base: #181d25;
  --neutral: #edf0f2;
  --warning-soft: #ffebc6;
  --warning: #f68500;
  --border-intense: #c3ced7;
  --text-muted: #929fb1;
  --primary-soft: #ebf1ff;
  --text-secondary: #606e80;
  --pink-primary: #ff33eb;
  --icon-base: #404b5a;
  --border-color: #e1e5ea;
  --primary-subtle: #5c6cff;
  --surface-base: #ffffff;
  --surface-lower: #f6f7f9;
  /* Add other color variables as needed */
}
@media screen and (max-width: 769px) {
   .noscroll{
    overflow-x: hidden
   }
  }
</style>

import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', {
  state: () => {
    const isMobile = window.innerWidth <= 768;
    return {
      isMobile,
      isOpen: false,
      activeTab: isMobile ? 'Info' : 'Overview',
      report_start: null,
      report_select: null,
      report_end: null,
      ClientSidebar_Open: true,
      BudgetSidebar_Open: true,
      MapSidebar_Open:false,
      AgentSidebar_Open:true,
      Sidebaropen:false,
      hideClientTabs: false,
      openOrderOpened: false,
      toggleMobileSearch: false,
      toggleMobilefilter:false,
      filterCount:0,
      editingRowIndex:null,
      toggleOfferIndexMobileSearch: false,
      toggleOfferIndexMobilefilter:false,
      offerIndexfilterCount:0,
    };
  },
  actions: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    setOpen(value) {
      this.isOpen = value;
    },
    setClient_Sidebar_Open(value){
      this.ClientSidebar_Open=value
    },
    Common_Sidebar_Open(value){
      this.Sidebaropen=value
    },
    restoreClientTabs() {
      this.hideClientTabs = false;
      this.openOrderOpened = false;
    },
  }
})
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/client',
    name: 'Client',
    component: () =>
        import ('../views/Client/views/ClientView.vue')
},
{
  path: '/client/:id',
  name: 'ClientDetails',
  component: () =>
      import ('../views/Client/views/ClientDetails.vue')
},
{
  path: '/map',
  name: 'Map',
  component: () =>
      import ('../views/Map/views/MapView.vue')
},
{
  path: '/notification',
  name: 'Notification',
  component: () =>
      import ('../views/Notification/views/NotificationView.vue')
},
{
  path: '/agent',
  name: 'Agent',
  component: () =>
      import ('../views/Agent/views/AgentView.vue')
},
{
  path: '/agent/:id',
  name: 'AgentDetails',
  component: () =>
      import ('../views/Agent/views/AgentDetails.vue')
},
{
  path: '/dashboard',
  name: 'Dashboard',
  component: () =>
      import ('../views/Dashboard/views/DashboardView.vue')
},
{
  path: '/map',
  name: 'Map',
  component: () =>
      import ('../views/Map/views/MapView.vue')
},
{
  path: '/offer',
  name: 'Offer',
  component: () =>
      import ('../views/Offer/views/OfferView.vue')
},
{
  path: '/offer/new/:id',
  name: 'OfferNew',
  component: () =>
      import ('../views/Offer/views/OfferNew.vue')
},
{
  path: '/offer/:id',
  name: 'OfferDetails',
  component: () =>
      import ('../views/Offer/views/OfferDetails.vue')
},
{
  path: '/price',
  name: 'Price',
  component: () =>
      import ('../views/Price/views/PriceView.vue')
},
{
  path: '/price/:id',
  name: 'PriceEdit',
  component: () =>
      import ('../views/Price/views/PriceEdit.vue')
},
{
  path: '/openorders',
  name: 'OpenOrders',
  component: () =>
      import ('../views/OpenOrders/views/OpenOrders.vue')
},
{
  path: '/appointment',
  name: 'Appointment',
  component: () =>
      import ('../views/Appointment/views/AppointmentView.vue')
},
{
  path: '/budget',
  name: 'Clienti',
  component: () =>
      import ('../views/Budget/views/BudgetView.vue')
},
{
  path: '/budget/:id',
  name: 'BudgetDetails',
  component: () =>
      import ('../views/Budget/views/BudgetDetails.vue')
},
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
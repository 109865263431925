import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import router from './router';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
// import { VueGoogleMaps } from '@fawmi/vue-google-maps';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import PrimeVue from 'primevue/config';
// vSelect.props.components.default = () => ({
  
//   OpenIndicator: {
//     render() {
//       return h('img', {
//         src: new URL('@/assets/img/client/search-blue.svg', import.meta.url),
//         alt: 'Open Indicator',
//         class: 'open-indicator-icon',
//       });
//     },
//   },
// });

import '@/assets/css/style.css';

const pinia = createPinia();
const app = createApp(App);
app.use(router);
app.use(pinia);
app.component('v-select', vSelect)
// app.use(VueGoogleMaps, {
//     load: {
//       key: 'YOUR_API_KEY', // Replace with your actual API key
//       libraries: 'places', // Optional, add other libraries if needed
//     },
//   });
app.use(PrimeVue);

app.mount('#app');

<template>
  <div
    id="app_sidebar"
    :class="!isMobile ? '' : sidebarStore.Sidebaropen ? '' : 'd-none'"
  >
    <div
      :class="isMobile ? 'mblsidebar' : 'sidebar'"
      @mouseover="setOpen(true)"
      @mouseleave="setOpen(false)"
    >
      <div class="Profile" v-if="isMobile">
        <div class="row">
          <div class="col-10">
            <p class="ps-3 fs_14 Profile_Text mt-2">Eleonora Zaffaroni</p>
            <p class="Sidebar_Subtext ps-3 fs_12 Mar-Top-n10">Sales manager</p>
          </div>
          <div class="col-2">
            <img src="@/assets/img/sidebar/arrow-right.svg" class="mt-3 Mar-Left-20" />
          </div>
        </div>
      </div>
      <div class="Sidebar-sticky pt-3">
        <nav>
          <router-link
            v-if="!isMobile && sidebarStore.isOpen"
            class="fs_15 sidebar-item"
            :class="isMobile ? 'mblsidebar-item' : 'sidebar-item'"
            to="/"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img src="@/assets/img/sidebar/eurojersey_logo.png" class="Sidebar_Logo" />
          </router-link>
          <router-link
            class="fs_15 mt-4"
            :class="isMobile ? 'mblsidebar-item' : 'sidebar-item'"
            to="/notification"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="notificationsImage" class="Pad-Class" />
            <span class="Sidebar_Text">Notifications</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="isMobile ? 'mblsidebar-item' : 'sidebar-item'"
            to="/dashboard"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="DashboardImage" class="Pad-Class" />
            <span class="Sidebar_Text">Dashboard</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActiveBudget]"
            to="/budget"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="BudgetImage" class="Pad-Class" />
            <span class="Sidebar_Text">Budget</span>
          </router-link>
          <router-link
            class="fs_15"
            to="/client"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActive]"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="UserImage" class="Pad-Class" />
            <span class="Sidebar_Text">Clients</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActivemap]"
            to="/map"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="MapImage" class="Pad-Class" />
            <span class="Sidebar_Text">Map</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActivePrice]"
            to="/price"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="PriceImage" class="Pad-Class" />
            <span class="Sidebar_Text">Price List</span>
          </router-link>
          <router-link
            class="fs_15"
            to="/agent"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActiveAgent]"
          >
            <img :src="AgentImage" class="Pad-Class" />
            <span class="Sidebar_Text">Agents</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActiveAppointment]"
            to="/appointment"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="AppointmentImage" class="Pad-Class" />
            <span class="Sidebar_Text">Appointments</span>
          </router-link>
          <router-link
            class="fs_15"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActiveOffer]"
            to="/offer"
            active-class="active-link"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="OffersImage" class="Pad-Class" />
            <span class="Sidebar_Text">Offers</span>
          </router-link>
          <router-link
            class="fs_15"
            to="/openorders"
            :class="[isMobile ? 'mblsidebar-item' : 'sidebar-item', isActiveopenorder]"
            @click="sidebarStore.Sidebaropen = !sidebarStore.Sidebaropen"
          >
            <img :src="OpenOrderImage" class="Pad-Class" />
            <span class="Sidebar_Text">Open Orders</span>
          </router-link>
        </nav>
      </div>

      <div class="d-flex align-items-center Avatar-Content" v-if="!isMobile">
        <div class="avatar-text">D</div>
      </div>

      <div class="bottom-item" v-if="!isMobile">
        <div class="Profile">
          <div class="row">
            <div class="col-10">
              <p class="ps-3 fs_14 Profile_Text mt-2">Eleonora Zaffaroni</p>
              <p class="Sidebar_Subtext ps-3 fs_12 Mar-Top-n10">Sales manager</p>
            </div>
            <div class="col-2">
              <img src="@/assets/img/sidebar/arrow-right.svg" class="mt-3 Mar-Left-20" />
            </div>
          </div>
          <div class="Align_class">
            <button
              type="button"
              class="Button_Class fs_13 mt-2 me-1"
              :class="activelang == 'italiano' ? 'active-lang' : ''"
              @click="activelang = 'italiano'"
            >
              Italiano
            </button>
            <button
              type="button"
              class="Button_Class fs_13 mt-2 p-1 ms-1"
              :class="activelang == 'inglese' ? 'active-lang' : ''"
              @click="activelang = 'inglese'"
            >
              Inglese
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
// Using ref to declare a reactive variable
let activelang = ref("italiano");
import { useSidebarStore } from "@/stores/sidebar";
const isMobile = ref(window.innerWidth <= 768);
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
};
onMounted(() => {
  window.addEventListener("resize", handleResize);
});

// Clean up the event listener when the component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
const sidebarStore = useSidebarStore();
const setOpen = (value) => sidebarStore.setOpen(value);

const notificationsImage = computed(() =>
  route.path === "/notifications"
    ? require("@/assets/img/sidebar/bell-notification.svg")
    : require("@/assets/img/sidebar/bell-notification.svg")
);
const DashboardImage = computed(() =>
  route.path === "/dashboard" || route.path.startsWith("/dashboard")
    ? require("@/assets/img/sidebar/dashboard-active.svg")
    : require("@/assets/img/sidebar/dashboard.svg")
);
const BudgetImage = computed(() =>
  route.path === "/budget" || route.path.startsWith('/budget')
    ? require("@/assets/img/budget/signal-level-blue.svg")
    : require("@/assets/img/sidebar/signal-level.svg")
);
const UserImage = computed(() =>
  route.path === "/client" || route.path.startsWith("/client")
    ? require("@/assets/img/sidebar/users-group-active.svg")
    : require("@/assets/img/sidebar/users-group.svg")
);
const AppointmentImage = computed(() =>
  route.path === "/appointment"
    ? require("@/assets/img/sidebar/date-calendar-active.svg")
    : require("@/assets/img/sidebar/date-calendar.svg")
);
const OpenOrderImage = computed(() =>
  route.path === "/openorders"
    ? require("@/assets/img/sidebar/view-list-active.svg")
    : require("@/assets/img/sidebar/view-list.svg")
);
const PriceImage = computed(() =>
  route.path === "/price" || route.path.startsWith("/price")
    ? require("@/assets/img/sidebar/book-open-active.svg")
    : require("@/assets/img/sidebar/book-open.svg")
);
const AgentImage = computed(() =>
  route.path === "/agent"|| route.path.startsWith("/agent")
    ? require("@/assets/img/sidebar/agent-active.svg")
    : require("@/assets/img/sidebar/agent.svg")
);
const OffersImage = computed(() =>
  route.path === "/offer" || route.path.startsWith('/offer')
    ? require("@/assets/img/sidebar/file-page-active.svg")
    : require("@/assets/img/sidebar/file-page.svg")
);
const MapImage = computed(() =>
  route.path === "/map"
    ? require("@/assets/img/sidebar/location-map-active.svg")
    : require("@/assets/img/sidebar/location-map.svg")
);
const isActive = computed(() => {
  console.log(
    "link",
    route.path === "/client" || route.path.startsWith("/client"),
    route.path
  );
  return route.path && (route.path === "/client" || route.path.startsWith("/client/"))
    ? "active-link"
    : "";
});
const isActiveAgent = computed(() => {
  
  return route.path && (route.path === "/agent" || route.path.startsWith("/agent/"))
    ? "active-link"
    : "";
});
const isActiveopenorder = computed(() => {
  
  return route.path && (route.path === "/openorders" || route.path.startsWith("/openorders/"))
    ? "active-link"
    : "";
});
const isActiveAppointment = computed(() => {
  
  return route.path && (route.path === "/appointment" || route.path.startsWith("/appointment/"))
    ? "active-link"
    : "";
});
const isActivePrice = computed(() => {
  
  return route.path && (route.path === "/price" || route.path.startsWith("/price/"))
    ? "active-link"
    : "";
});
const isActivemap = computed(() => {
  
  return route.path && (route.path === "/map" || route.path.startsWith("/map/"))
    ? "active-link"
    : "";
});
const isActiveOffer = computed(() => {
  console.log(
    "link",
    route.path === "/offer" || route.path.startsWith("/offer"),
    route.path
  );
  return route.path && (route.path === "/offer" || route.path.startsWith("/offer/"))
    ? "active-link"
    : "";
});
const isActiveBudget = computed(() => {
  console.log(
    "link",
    route.path === "/budget" || route.path.startsWith("/budget"),
    route.path
  );
  return route.path && (route.path === "/budget" || route.path.startsWith("/budget/"))
    ? "active-link"
    : "";
});
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app_sidebar {
  display: flex;
}

.sidebar {
  width: 79px;
  height: 100vh;
  overflow-x: hidden;
  opacity: 1px;
  box-shadow: 1px 1px 4px 0px #929fb180;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 30px 20px;
  top: 0px;
  transition: width 0.3s;
  background: white;
}

.sidebar:hover {
  width: 231px;
}

.Sidebar-sticky {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 44px;
  font-family: "IntersemiBold";
  line-height: 18.15px;
  text-align: left;
  color: var(--text-base);
  text-decoration: none;
}

.sidebar-item .Pad-Class {
  padding: 0px 8px 0px 0px;
}

.sidebar-item .Sidebar_Text {
  display: none;
  white-space: nowrap;
}

.sidebar:hover .Sidebar_Text {
  display: inline;
}

.sidebar-item .Sidebar_Logo {
  display: none;
}

.sidebar:hover .Sidebar_Logo {
  display: inline;
}

.sidebar .bottom-item {
  display: none;
  white-space: nowrap;
}

.sidebar:hover .bottom-item {
  display: inline;
}

.sidebar:hover .Avatar-Content {
  display: none !important;
  white-space: nowrap;
}

.sidebar .Avatar-Content {
  display: inline;
}
.Mar-Left-20 {
  margin-left: -20px;
}
.Profile {
  box-shadow: 0px 2px 4px 0px #8989891f !important;
  border: 1px solid #e1e5ea !important;
  width: 191px !important;
  height: 60px;
  justify: space-between;
  border-radius: 10px;
}

.Profile_Text {
  font-family: "IntersemiBold";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.Sidebar_Subtext {
  font-family: "Intermedium";
  line-height: 18.15px;
  text-align: left;
  color: #3348ff;
}

.bottom-item {
  margin-top: auto;
  padding: 0px 0px 30px 0px;
  width: 100%;
}

.Button_Class {
  width: 66px;
  height: 32px;
  border: 1px solid #e1e5ea !important;
  border-radius: 10px;
  text-align: center;
  background: transparent;
}

.active-lang {
  border: 1px solid #3348ff !important;
  box-shadow: 0px 0px 0px 3px #99bfff80 !important;
  color: #3348ff !important;
}
.active-link {
  color: var(--text-highlight) !important;
  background: var(--primary-soft);
  border-radius: 8px;
}
.router-link {
  color: var(--text-base) !important;
}
.Align_class {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--border-intense);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--text-highlight);
  border-radius: 2px;
  height: 58px !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-highlight);
  cursor: pointer !important;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.avatar-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--text-muted);
  color: white;
  border-radius: 50%;
  font-family: "IntersemiBold";
  font-size: 16px;
}
.mblsidebar {
  margin-top: 50px;
  width: 290px;
  height: 100vh;
  overflow-x: hidden;
  opacity: 1px;
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 30px 20px;
  top: 0px;
  transition: width 0.3s;
  background: white;
}

.mblsidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 44px;
  font-family: "IntersemiBold" !important;
  font-weight: 600 !important;
  line-height: 18.15px;
  text-align: left;
  color: var(--text-base);
  text-decoration: none;
}

.mblsidebar-item .Pad-Class {
  padding: 0px 8px 0px 0px;
}

.mblsidebar-item .Sidebar_Text {
  display: inline;
}

.sidebar-item .Sidebar_Logo {
  display: inline;
}
</style>

